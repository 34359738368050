@accent-color: rgba(200,90,90,1);
@side-color: rgba(150,150,150,1);

body {
    padding: 0;
    margin: 0;
    color: rgba(70, 70, 70, 1);
}
ul {
    list-style: none;
    margin: 0;
    padding: 0;
}
ul.list {
    list-style: inherit;
    margin-left: 1rem;
}
h5 {
    margin-top: 0;
    margin-bottom: 0;
}
label {
    margin-bottom: 0;
}

header {
    position: relative;
    border-bottom: solid 1px rgba(0,0,0,.1);
    margin-right: 2px;
    margin-left: 1px;
    h1 {
        font-size: 2rem;
        font-weight: bold;
        color: @accent-color;
        margin-bottom: 0;
    }
    h2 {
        font-size: .8rem;
        font-weight: bold;
        color: @side-color;
        margin-top: 0;
    }
    .main-menu-button {
        position: absolute;
        bottom: 3px;
        right: 3px;
    }
}
footer {
    margin-top: .3rem;
    margin-bottom: .3rem;
    font-size: .7rem;
    h5 {
        font-size: 1em;
        color: @accent-color;
        font-weight: bold;
    }
    .description {
        font-size: .8em;
    }
}

.hidden {
    display: none !important;
}
.has-error {
    color: rgba(255,0,0,1);
    background-color: rgba(255,0,0,.2);
}
.new-release {
    color: rgba(255, 0, 0, 1);
    font-weight: bolder;
}

.main-contents {
    border-spacing: 0;
    margin-top: .5rem;
    .label-now {
        text-align: right;
        font-size: .8rem;
        font-weight: bolder;
        border-right: solid 1px rgba(200, 200, 200, 1);
    }
    td {
        border-bottom: solid 1px rgba(200, 200, 200, 1);
        white-space: nowrap;
    }
    td.boss-info {
        text-align: right;
        padding-right: 8px;
    }
    td.repop-info {
        border-right: solid 1px rgba(200, 200, 200, 1);
    }
    td.time {
        width: 100%;
    }
}
.label-boss-name {
    font-size: .8em;
    font-weight: bold;
}
.label-repop-time {
    font-size: .6em;
    color: @side-color;
    font-weight: bold;
}
.label-region-and-area {
    font-size: .8em;
    color: @side-color;
    font-weight: bold;
}
.label-time {
    font-size: .5em;
}
.time-bar {
    display: block;
    height: 2.5rem;
    line-height: 2.5rem;
    font-size: .7rem;
    font-weight: bolder;
    cursor: pointer;
}
.description {
    font-size: .7rem;
    font-weight: bold;
    color: @side-color;
}
tr.reliable {
    background-color: rgba(255,230,230,1);
}
tr.unreliable {
    background-color: rgba(245,245,245,1);
}
.time-bar-color-short {
    background-color: rgba(255,200,200,1);
}
.time-bar-color-middle {
    background-color: rgba(200,200,255,1);
}
.time-bar-color-long {
    background-color:rgba(200,255,200,1)
}
.filter-container {
    display: inline-block;
    * {
        display: inline-block;
    }
    h4 {
        margin-top: 0;
        margin-bottom: 0;
    }
    ul {
        border: solid 1px rgba(0,0,0,.2);
        padding: 4px;
        margin-bottom: 1px;
        margin-left: 1px;
        border-radius: 2px;
    }
    li {
        margin-right: 1em;
    }
}
label.checkbox {
    cursor: pointer;
}
.input-row {
    > input {
        display: inline;
        width: auto;
    }
}
.notification-switcher {
    border-radius: 50%;
    font-size: .8rem;
    cursor: pointer;
    margin-left: 1px;
    margin-right: 4px;
    padding: 6px;
    background-color: rgba(180,180,180,1);
}
.unnotification {
    color: rgba(150,150,150,1);
}
.notification {
    color: rgba(255, 255, 0, 1);
}
.fa-edit {
    color: rgba(240,240,240,1);
    background-color: rgba(180,180,180,1);
    border-radius: 50%;
    font-size: .6rem;
    cursor: pointer;
    margin-left: 3px;
    padding: 3px;
}
.checked {
    color: rgba(255,255,255,1);
    background-color: rgba(30,100,30,1);
    border-radius: 50%;
}
.unchecked {
    color: rgba(150,150,150,1);
}

.dialog-contents {
    margin: 1em;
    padding: 1em;
    border :solid 1px rgba(0,0,0,.2);
    border-radius: 4px;
    background-color: white;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    max-height: 96vh;
    overflow: scroll;

    .input-group {
        margin-bottom: .5rem;
    }

    .btn-group {
        margin-top: .5rem;
    }
}
.backdrop {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,.7);
    backdrop-filter: blur(3px);
}
.hidden {
    display: none;
}
.container-body-and-backdrop {
    position: relative;
}
.region-custom-filter-container {
    margin-bottom: .5em;
    border-bottom: solid 1px rgba(30,30,30,.2);
}
.custom-filter-boss-label {
    display: inline-block;
}
.custom-filter-boss-area {
    color: rgba(100, 100, 80, 1);
    font-size: 60%;
    font-weight: bold;
    display: block;
}
.custom-filter-boss-name {
    color: rgba(60, 60, 50, 1);
    font-size: 80%;
    font-weight: bold;
}
.custom-filter-boss {
    cursor: pointer;
    display: inline-block;
    margin-bottom: 3px;
    margin-right: 1em;
}

.container-fb-icon {
    display: inline-flex;
    padding: 4px 4px 4px 4px;
    border: solid 1px rgba(230, 230, 230, 1);
}
.force-boss {
    background-color: rgba(199,21,133,1);
}
.unforce-boss {
    background-color: rgba(255,255,255,1);
}
span[class^=fb-icon-] {
    width: 32px;
    height: 32px;
    display: inline-block;
    background-color: rgba(255,255,255,.8);
}
.fbIcon(@bossId) {
    background-image: url("../img/fb-@{bossId}@32x32.png");
}

.fb-icon-kongou_rikishi {
    .fbIcon("kongou_rikishi");
}
.fb-icon-kien_hasami_mushi {
    .fbIcon("kien_hasami_mushi");
}
.fb-icon-kokusyouzoku_zokuchou {
    .fbIcon("kokusyouzoku_zokuchou");
}
.fb-icon-yagoruta {
    .fbIcon("yagoruta");
}
.fb-icon-kokujuushin {
    .fbIcon("kokujuushin");
}
.fb-icon-porukorosso {
    .fbIcon("porukorosso");
}
.fb-icon-goruraku {
    .fbIcon("goruraku");
}
.fb-icon-kiban {
    .fbIcon("kiban");
}
.fb-icon-ingenrai {
    .fbIcon("ingenrai");
}
.fb-icon-butan {
    .fbIcon("butan");
}
.fb-icon-surudoi_kiba {
    .fbIcon("surudoi_kiba");
}
.fb-icon-chacha {
    .fbIcon("chacha");
}
.fb-icon-sinbuu {
    .fbIcon("sinbuu");
}
.fb-icon-uta {
    .fbIcon("uta");
}
.fb-icon-fiku_kou {
    .fbIcon("fiku_kou");
}
.fb-icon-boukyaku_no_keikoku {
    .fbIcon("boukyaku_no_keikoku");
}
.fb-icon-boukyaku_no_meikyuu {
    .fbIcon("boukyaku_no_meikyuu");
}
.fb-icon-onkon_no_haikyo {
    .fbIcon("onkon_no_haikyo");
}
.fb-icon-tasogare_no_meikyuu {
    .fbIcon("tasogare_no_meikyuu");
}
.fb-icon-yussi-dofun {
    .fbIcon("yussi-dofun");
}
.fb-icon-wohyo {
    .fbIcon("wohyo");
}
.fb-icon-yougan_hasamimushi {
    .fbIcon("yougan_hasamimushi");
}
.fb-icon-yougan_sasori {
    .fbIcon("yougan_sasori");
}
.fb-icon-hakon {
    .fbIcon("hakon");
}
.fb-icon-harei {
    .fbIcon("harei");
}
.fb-icon-charo_eeru {
    .fbIcon("charo_eeru");
}
.fb-icon-koro_saigetsu {
    .fbIcon("koro_saigetsu");
}
.fb-icon-kuuhuku {
    .fbIcon("kuuhuku");
}
.fb-icon-yukionna {
    .fbIcon("yukionna");
}
.fb-icon-fin_zauru {
    .fbIcon("fin_zauru");
}
.fb-icon-dowol {
    .fbIcon("dowol");
}
.fb-icon-munnki {
    .fbIcon("munnki");
}
.fb-icon-buutara {
    .fbIcon("buutara");
}
.fb-icon-tyohii {
    .fbIcon("tyohii");
}